<template>
  <v-card
    :id="`videoContainer-${videoId}`"
    :class="playerClass"
    :outlined="$vuetify.theme.dark"
  >
    <video
      ref="player"
      fluid
      preload
      controls
      :poster="thumbnail"
      class="video-js vjs-big-play-centered"
    ></video>
  </v-card>
</template>

<script>
import videojs from 'video.js/dist/video';

import 'video.js/dist/video-js.min.css';
import '@assets/styles/video-js.css';
import '@silvermine/videojs-quality-selector/dist/css/quality-selector.css';
require('@silvermine/videojs-quality-selector')(videojs);
import 'videojs-hotkeys/videojs.hotkeys.min';

require('@hisolver/videojs-theater-mode/dist/videojs.theaterMode');
require('@hisolver/videojs-theater-mode/dist/videojs.theaterMode.css');

import { mapGetters, mapMutations } from 'vuex'

export default {
  props: {
    options: {
      type: Object,
      default () {
        return {
          controls: true,
          autoplay: false,
          responsive: true,
          playbackRates: [0.5, 1, 1.5, 2]
        }
      }
    },
    videoId: {
      type: String,
      default: 'solo'
    },
    url: {
      type: String,
      required: true
    },
    thumbnail: {
      type: String,
      default: ''
    },
    isPreview: {
      type: Boolean,
      default: false
    },
    syncTheaterMode: {
      type: Boolean,
      default: true
    },
    mediaViewer: {
      type: Boolean,
      default: false
    },
    syncMediaViewer: {
      type: Boolean,
      default: false
    }
  },

  mounted() {

    this.setupVideo()

    const resizeObserver = new ResizeObserver(([{ target }]) => {
      this.$emit('playerHeight', {
        height: target.clientHeight,
        theaterModeOn: this.theaterModeOn
      })
    })

    resizeObserver.observe(this.$refs.player)

  },

  beforeDestroy() {

    if (this.player) {

      this.player.dispose()
    
    }

    this.setNavOn(true)
    this.setNavTempOn(false)
    
    if (this.navTemp) {
    
      this.toggleNavTemp()
    
    }

  },

  data() {
    return {
      player: null,
      theaterModeOn: false
    }
  },

  watch: {

    url() {
      this.setupVideo()
    },

    syncMediaViewer() {
      this.player.pause()
    },

    theaterModeOn (theater) {
      this.$emit('theaterModeToggle', theater)
    }

  },

  computed: {
    
    ...mapGetters({
      innerHeight: 'innerHeight',
      navTempOn: 'application/navTempOn',
      navTemp: 'application/navTemp',
      navOn: 'application/navOn'
    }),

    containerHeight() {
      return this.innerHeight * (1 - (100 / this.innerHeight))
    },

    playerClass () {
      const mediaViewerClass = 'video-js-media-viewer';
      const baseClass = 'video-js-responsive vjs-hd';
      const breakPoint = this.$vuetify.breakpoint.xl ? 'xl' : 'lg';
      const theaterClass = `video-js-theater video-js-theater-${breakPoint}`;

      return this.theaterModeOn && this.syncTheaterMode
        ? theaterClass
        : this.mediaViewer
        ? `${baseClass} ${mediaViewerClass}`
        : baseClass
    }
    
  },

  methods: {

    ...mapMutations({
      setNavOn: 'application/navOn',
      toggleNavTemp: 'application/navTemp',
      setNavTempOn: 'application/navTempOn'
    }),

    setupVideo() {

      const src = this.url;

      this.player = videojs(this.$refs.player, this.options, function onPlayerReady() {

        if (this.hotkeys) {
        
          const hotkeysOptions = {
            volumeStep: 0.1,
            seekStep: 5,
            enableModifiersForNumbers: false
          }
          
          this.hotkeys(hotkeysOptions)
        
        }

        this.src([{src, type: 'video/mp4'}])
        
      })

      if (!this.isPreview) {

        this.player.theaterMode({
          elementToToggle: `videoContainer-${this.videoId}`,
          className: 'video-js-theater'
        })

        this.player.on('theaterMode', (el, { theaterModeIsOn }) => {

          this.theaterModeOn = theaterModeIsOn
          this.$emit('theaterMode', theaterModeIsOn)

          if (this.syncTheaterMode) {

            this.setNavOn(!theaterModeIsOn)
            this.setNavTempOn(theaterModeIsOn)
          
          } else {
          
            this.player.pause()
          
          }

        })

      }

    }

  }

}
</script>

<style scoped>
.video-js-responsive {
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 40px;
}
.video-js-responsive .video-js {
  height: 100% !important; 
  width: 100% !important;
  position: absolute;
  top: 0;
  left: 0;
}

.video-js-theater { 
  width: 100%;
  position: relative;
}

.video-js-theater-xl .video-js { 
  width: 100%;
  height: 60em;
  position: absolute;
  top: 0;
  left: 0;
}

.video-js-theater-lg .video-js { 
  width: 100%;
  height: 40em;
  position: absolute;
  top: 0;
  left: 0;
}
</style>