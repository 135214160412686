<template>
  <div class="scrolling-wrapper">
    <v-btn icon @click="scroll(-1)" class="scroll-btn left-btn">
      <v-icon>
        {{ mdiChevronLeft }}
      </v-icon>
    </v-btn>

    <div class="scroll-container" ref="scrollContainer">
      <v-card
        class="my-4 mr-8 scroll-card"
        v-for="(card, index) in cards"
        :key="index"
        @click="$emit('setContent', card)"
      >
        <v-img
          :src="card.img"
          gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
          height="200"
          :height="`${containerHeight * 0.25}px`"
          class="white--text align-end">
          <v-card-title>{{ card.title }}</v-card-title>
        </v-img>
      </v-card>
    </div>

    <v-btn icon @click="scroll(1)" class="scroll-btn right-btn">
      <v-icon>
        {{ mdiChevronRight }}
      </v-icon>
    </v-btn>
  </div>
</template>


<script>
import { mdiChevronRight, mdiChevronLeft } from '@mdi/js'
import { mapGetters } from 'vuex'

export default {

  computed: {
    ...mapGetters({
      innerHeight: 'innerHeight'
    }),

    containerHeight() {
      return this.innerHeight * (1 - (100 / this.innerHeight))
    }
  },

  data() {
    return {
      mdiChevronLeft,
      mdiChevronRight,

      cards: [
        {
          title: 'The Diagram',
          code: 'diagram',
          img: 'https://bkt-hack-mathchat.s3.amazonaws.com/OO-FTA/DiagramScene.png',
          url: 'https://bkt-hack-mathchat.s3.amazonaws.com/OO-FTA/DiagramScene.mp4',
          type: 'video'
        },
        {
          title: 'The Pyramid',
          code: 'pyramid',
          img: 'https://bkt-hack-mathchat.s3.amazonaws.com/OO-FTA/PyramidScene-2.png',
          url: 'https://bkt-hack-mathchat.s3.amazonaws.com/OO-FTA/PyramidScene-2.mp4',
          type: 'video'
        }        
      ]
    };
  },
  methods: {
    scroll(direction) {
      const container = this.$refs.scrollContainer;
      const scrollAmount = 300; // Adjust based on your card size and desired scroll amount
      const currentScroll = container.scrollLeft;
      container.scrollTo({ left: currentScroll + (direction * scrollAmount), behavior: 'smooth' });
    },
  },
};
</script>

<style scoped>
.scrolling-wrapper {
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden; /* Ensures the overflow content is clipped and scrollable */
}

.scroll-container {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}

.scroll-container::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.scroll-card {
  flex: 0 0 auto; /* Prevent flex items from growing but allows shrinking */
  max-width: 250px;
  border-radius: 30px;
}

/* Style adjustments for the arrow buttons */
.scroll-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.5); /* Semi-transparent background */
  border: none;
  cursor: pointer;
  z-index: 2;
}

.left-btn {
  left: 0;
}

.right-btn {
  right: 0;
}

.v-icon {
  color: #000; /* Icon color */
}
</style>

