import css from './index.css';

function isVuetifyThemeDark() {
  return document.querySelector('.v-application').classList.contains('theme--dark');
}

export default class AnyButton {

    static get toolbox() {
      return { 
        title: "Button",
        icon: '<svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" enable-background="new 0 0 512 512" height="20" viewBox="0 0 512 512" width="20"><path d="m237.102 366v-90.018h-90c-11.046 0-20-8.954-20-20s8.954-20 20-20h90v-90.982c0-11.046 8.954-20 20-20s20 8.954 20 20v90.982h90c11.046 0 20 8.954 20 20s-8.954 20-20 20h-90v90.018c0 11.046-8.954 20-20 20s-20-8.954-20-20zm254.898-15c11.046 0 20-8.954 20-20v-251c0-44.112-35.888-80-80-80h-352c-44.112 0-80 35.888-80 80v352c0 44.112 35.888 80 80 80h352c44.112 0 80-35.888 80-80 0-11.046-8.954-20-20-20s-20 8.954-20 20c0 22.056-17.944 40-40 40h-352c-22.056 0-40-17.944-40-40v-352c0-22.056 17.944-40 40-40h352c22.056 0 40 17.944 40 40v251c0 11.046 8.954 20 20 20z"/></svg>'
      };
    }
  
    static get isReadOnlySupported() {
      return true;
    }
  
    constructor({ api }) {
      this.api = api;
      this.nodes = {
        wrapper: null,
        buttonContainer: null,
        button: null,
      };
    }

    render() {
      this.nodes.wrapper = this.make('div', ['anyButtonContainer']);
      // this.nodes.toggleHolder = ...; // toggle logic removed
      // this.nodes.inputHolder = ...; // input holders removed
    
      // Button holder and button
      this.nodes.buttonContainer = this.make('div', ['anyButtonContainer__anyButtonHolder']);
      this.nodes.button = this.make('button', ['btn', 'btn--primary'], {
        type: 'button', // Specify button type to avoid form submission if nested in a form
        textContent: 'add activity'
      });

      console.log(isVuetifyThemeDark())

      // if (isVuetifyThemeDark()) {
      //   this.nodes.button.style.backgroundColor = '#8348ca';
      // }
  
      // Event listener for the click event
      this.nodes.button.addEventListener('click', () => {
        window.dispatchEvent(new CustomEvent('anyButtonClicked', {
          detail: { message: 'Button clicked' }
        }));
      });
  
      this.nodes.buttonContainer.appendChild(this.nodes.button);
      this.nodes.wrapper.appendChild(this.nodes.buttonContainer);
  
      return this.nodes.wrapper;
    }
  
    save() {
      return {};
    }
  
    renderView() {
      return this.render();
    }
  
    destroy() {
      if (this.nodes.button) {
        this.nodes.button.removeEventListener('click', this.onButtonClick);
      }
    }
  
    make(tagName, classNames = null, attributes = {}) {
      const el = document.createElement(tagName);
  
      if (Array.isArray(classNames)) {
        el.classList.add(...classNames);
      } else if (classNames) {
        el.classList.add(classNames);
      }
  
      for (const attrName in attributes) {
        if (attributes.hasOwnProperty(attrName)) {
          const value = attributes[attrName];
          if (attrName === 'textContent') {
            el.textContent = value;
          } else {
            el.setAttribute(attrName, value);
          }
        }
      }
  
      return el;
    }
  }
  