<template>
  <div>    
    <v-row align="start" justify="start">
      <v-col class="py-0 px-8">
        <Notebook />

        <div v-if="false" :style="`height: ${containerHeight * 0.65}px;`">
          <ManimSlides
            v-if="showSlides && !loading"
            :content="content"
          />

          <RoundedPlayer
            v-else-if="!loading"
            videoId="demo-video"
            :url="content.url"
            :thumbnail="content.img"
            :options="{
              controls: true,
              autoplay: false,
              responsive: true,
              playbackRates: [0.5, 1, 1.5, 2] 
            }"
            :isPreview="true"
            :mediaViewer="true"
          />
        </div>

        <ContentChatModules v-if="false" @setContent="setContent" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { MediaURL } from '@components'
import { RoundedPlayer } from '@components/Video';
import ManimSlides from '@components/ManimSlides.vue'
import ContentChatModules from '@components/Content/ContentChatModules'
import Notebook from '@components/Notebook'
import Script from '@components/Content/Script'

import API from '@api'


export default {
  components: {
    Notebook,
    ManimSlides,
    RoundedPlayer,
    ContentChatModules
  },

  async created() {
    this.getVideoThumbnail = MediaURL.getVideoThumbnail.bind(this);
    this.getMediaUrl = MediaURL.getMediaUrl.bind(this);

    this.loading = false

    this.SET_CONTEXT(Script.getContext('pyramid_heuristics'))
  },

  data: () => ({
    tab: 0,
    loading: false,
    write: true,
    welcome: true,
    content: {
      title: 'Pyramid Heuristics',
      img: 'https://bkt-hack-mathchat.s3.amazonaws.com/OO-FTA/5055427999658257705.jpg',
      url: 'https://bkt-hack-mathchat.s3.amazonaws.com/OO-FTA/PyramidScene-2.mp4',
      type: 'video'
    },
    index: 0
  }),

  computed: {
    ...mapGetters({
      innerHeight: 'innerHeight',
      showChat: 'showChat',
      showSlides: 'showSlides',
      anna: 'anna',
      newUser: 'newUser',
      myProfileId: 'profile/id',
      myName: 'profile/name',
      user: 'user',
      annaChamber: 'annaChamber',
      from: 'from',
      studyTool: 'studyTool'
    }),


    containerHeight() {
      return this.innerHeight * (1 - (100 / this.innerHeight))
    },

    col() {
      const xl = this.$vuetify.breakpoint.xl
      return this.annaChamber ? 100 : xl ? 72 : 70
    },

    // TODO: review this; ideally we will remove as many ad hoc solutions as we can
    padding () {
      return this.$vuetify.breakpoint.lg ? 'pl-0 pr-0' : 'pr-1'
    },

    navColor() {
      return !this.$vuetify.theme.dark
        ? 'grey lighten-5'
        : '#1a1a1a'
    },

    borderStyle() {
      return this.$vuetify.theme.dark
        ? 'transparent !important;'
        : 'thin solid rgba(0, 0, 0, 0.12) !important;'
    }
  },

  methods: {
    ...mapMutations({
      SET_CONTEXT: 'SET_CONTEXT',
      SET_SLIDES: 'SET_SLIDES',
    }),

    setContent(content) {
      this.loading = true

      this.content = content;
      if (content.type === 'slides')
        this.SET_SLIDES(true)
      else
        this.SET_SLIDES(false)

      const contentCotext = Script.getContext(content.code)

      this.SET_CONTEXT(contentCotext)

      setTimeout(() => {
        this.loading = false
      }, 100)
    }
  }
}
</script>

<style scoped>
.scroll-container {
  overflow-x: auto; /* Allows horizontal scrolling */
  white-space: nowrap; /* Prevents wrapping of contents */
}

.scroll-row {
  display: flex;
  flex-wrap: nowrap; /* Prevents cards from wrapping */
}

/* Deprecated in Vue 3 */
::v-deep .v-dialog {
  border-radius: 24px !important;
}

.circle {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  box-shadow: 0px 0px 0.5px 0.5px #e9e7911a;
  background:#f8f6927e;
}
.pulse {
  animation: pulse-animation 1.5s infinite;
}
@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(248, 241, 140, 0.322);
  }
  100% {
    box-shadow: 0 0 0 15px rgba(255, 253, 151, 0.377);
  }
}
</style>